import React, { createContext, useContext, useMemo, useState } from "react";

const darkModeClass = "dark";
const lightModeClass = "light";
const themeKey = "theme";

const getStoredTheme = () => {
  if (
    document.documentElement.classList.contains(darkModeClass) ||
    localStorage.theme === darkModeClass ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    return darkModeClass;
  } else {
    return lightModeClass;
  }
};

export type Theme = ReturnType<typeof getStoredTheme>;
export type ThemeContext = {
  currentTheme: Theme;
  switchTheme: () => Theme;
};

const ThemeContext = createContext<ThemeContext | null>(null);

export const ThemeProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(getStoredTheme);
  function switchTheme() {
    const newTheme =
      currentTheme == darkModeClass ? lightModeClass : darkModeClass;

    setCurrentTheme(newTheme);
    window.localStorage.setItem(themeKey, newTheme);

    const classList = document.documentElement.classList;
    if (newTheme == darkModeClass) {
      classList.add(darkModeClass);
    } else {
      classList.remove(darkModeClass);
    }
    return newTheme;
  }

  const contextValue = useMemo(
    () => ({ currentTheme: currentTheme as Theme, switchTheme }),
    [currentTheme]
  );
  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context == null) {
    throw new Error("Cannot access theme outside of ThemeContext.Provider");
  }
  return context;
};
