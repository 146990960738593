import { NavLink } from "../components/NavLink";

export const NavBar: React.FC = () => {
  return (
    <nav className="-mr-4 flex max-w-full flex-wrap justify-start">
      <NavLink to="/resume" className="mr-4 font-bold" end>
        Resume
      </NavLink>
      <NavLink to="/projects" className="mr-4 font-bold" end>
        Projects
      </NavLink>
      <NavLink to="/garden" className="mr-4 font-bold">
        Garden
      </NavLink>
    </nav>
  );
};
