import { createBrowserRouter } from "react-router";
import { HomePage } from "./pages/HomePage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { getGardenNotes } from "./scripts/notes";

export const Router = createBrowserRouter([
  {
    path: "*",
    element: <NotFoundPage />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "resume",
    lazy: async () => {
      const { ResumePage } = await import("./pages/ResumePage");
      return { element: <ResumePage /> };
    },
    hydrateFallbackElement: <></>,
  },
  {
    path: "projects",
    lazy: async () => {
      const { ProjectsPage } = await import("./pages/ProjectsPage");
      return { element: <ProjectsPage /> };
    },
    hydrateFallbackElement: <></>,
  },
  {
    path: "garden",
    lazy: async () => {
      const { GardenIndexPage } = await import("./pages/GardenIndexPage");
      return { element: <GardenIndexPage /> };
    },
    hydrateFallbackElement: <></>,
  },
  {
    path: "garden/:noteId",
    loader: async ({ params }) => {
      const notes = getGardenNotes();
      const id = params.noteId;
      if (id && Object.prototype.hasOwnProperty.call(notes, id)) {
        return notes[id]();
      }
      throw new Response(null, { status: 404 });
    },
    lazy: async () => {
      const { RenderedNotePage } = await import("./pages/RenderedNotePage");
      return { element: <RenderedNotePage /> };
    },
    hydrateFallbackElement: <></>,
    errorElement: <NotFoundPage />,
  },
]);
