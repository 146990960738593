import { useEffect } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { WireWorldCanvas } from "./WireWorldWallpaper/Canvas";

type CommonPageProps = {
  title: string;
  children?: React.ReactNode;
};

export const CommonPage: React.FC<CommonPageProps> = (props) => {
  useEffect(() => {
    document.title = props.title;
  }, []);
  return (
    <>
      <div className="flex min-h-screen w-full flex-col font-sans text-slate-900 dark:text-slate-100">
        <WireWorldCanvas />
        <Header />
        <main className="m-4 grow lg:ps-[calc(100vw-100%)]">
          <div className="mx-auto w-full max-w-screen-lg">{props.children}</div>
        </main>
        <Footer />
      </div>
    </>
  );
};
