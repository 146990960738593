import { RouterProvider } from "react-router";
import { ThemeProvider } from "./contexts/theme";
import { Router } from "./router";

export const App: React.FC = () => {
  return (
    <ThemeProvider>
      <RouterProvider router={Router} />
    </ThemeProvider>
  );
};
