import Github_logo from "../assets/github-logo.svg?react";
import Linkedin_logo from "../assets/linkedin-logo.svg?react";
import { Link } from "../components/Link";

export const Footer: React.FC = () => {
  return (
    <footer className="w-full bg-slate-200 lg:ps-[calc(100vw-100%)] dark:bg-slate-800">
      <div className="mx-auto flex max-w-screen-lg flex-wrap justify-between gap-2 p-4">
        <div className="flex gap-6">
          <Link
            isExternal
            to="https://github.com/Rubikium"
            className="-m-4 shrink-0 p-4"
          >
            <Github_logo className="size-6 text-slate-700 dark:text-slate-300" />
            <span className="sr-only">Link to Rubikium's Github</span>
          </Link>
          <Link
            isExternal
            to="https://www.linkedin.com/in/ki-fu-rubikium/"
            className="-m-4 shrink-0 p-4"
          >
            <Linkedin_logo className="size-6 text-slate-700 dark:text-slate-300" />
            <span className="sr-only">Link to Rubikium's Linkedin</span>
          </Link>
        </div>
        <span className="w-max text-slate-600 dark:text-slate-400">
          Rubikium &copy; <time>2025</time>
        </span>
      </div>
    </footer>
  );
};
