import { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router";
import { useTheme, Theme } from "../../contexts/theme";
import { usePrefersReducedMotion } from "../../hooks/usePrefersReducedMotion";
import { throttle } from "../../utils/throttle";
import { WireWorldGrid } from "./gridState";

const FPS = 24;
const lightCellColours: Record<number, string> = {
  1: "oklch(90% 0.1 210)",
  2: "oklch(90% 0.07 135)",
  3: "oklch(95% 0.05 90)",
};
const darkCellColours: Record<number, string> = {
  1: "oklch(40% 0.1 210)",
  2: "oklch(40% 0.07 135)",
  3: "oklch(30% 0.05 90)",
};
const grid = new WireWorldGrid();
const hexRadius = grid.hexRadius;
const hexWidth = grid.hexWidth;

const hexPath = (() => {
  const p = new Path2D();
  p.moveTo(0, -hexRadius);
  p.lineTo(hexWidth, -hexRadius / 2);
  p.lineTo(hexWidth, hexRadius / 2);
  p.lineTo(0, hexRadius);
  p.lineTo(-hexWidth, hexRadius / 2);
  p.lineTo(-hexWidth, -hexRadius / 2);
  p.closePath();
  return p;
})();

const render = (
  canvasRef: React.RefObject<HTMLCanvasElement | null>,
  currentTheme: Theme = "dark",
  isFullRender: boolean = true
) => {
  const ctx = canvasRef.current?.getContext("2d");
  if (ctx == null) return;

  if (isFullRender) {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }
  const minCol = Math.floor(window.scrollX / hexWidth);
  const minRow = Math.floor(window.scrollY / (hexRadius * 1.5));
  const maxCol = (ctx.canvas.width + window.scrollX) / hexWidth + 2;
  const maxRow = (ctx.canvas.height + window.scrollY) / (hexRadius * 1.5) + 2;

  for (let c = minCol; c < maxCol; c++) {
    for (let i = minRow; i < maxRow; i++) {
      const j = c + (i >> 1);
      const state = grid.getState(i, j);
      const prevState = grid.getPrevState(i, j);
      if (state == 0) continue;
      const [x, y] = grid.indicesToScreen(i, j);
      if (isFullRender || state != prevState) {
        ctx.save();
        ctx.translate(x - window.scrollX, y - window.scrollY);
        if (currentTheme == "dark") {
          ctx.fillStyle = darkCellColours[state];
        } else {
          ctx.fillStyle = lightCellColours[state];
        }
        ctx.fill(hexPath);
        ctx.restore();
      }
    }
  }
};

export const WireWorldCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  const { currentTheme } = useTheme();
  const location = useLocation();

  const resizeHandler = () => {
    if (canvasRef.current) {
      canvasRef.current.width = window.innerWidth;
      canvasRef.current.height = window.innerHeight;
    }
    render(canvasRef, currentTheme, true);
  };
  const scrollHandler = () => {
    render(canvasRef, currentTheme, true);
  };
  const mouseHandler = throttle((e: MouseEvent) => {
    if (prefersReducedMotion) return;
    const [i, j] = grid.screenToIndices(e.pageX, e.pageY);
    if (grid.getState(i, j) == 3 && grid.getPrevState(i, j) == 3) {
      grid.setState(i, j, 1);
    }
  }, 1000 / FPS);
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    window.addEventListener("scroll", scrollHandler);
    window.addEventListener("mousemove", mouseHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
      window.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("mousemove", mouseHandler);
    };
  }, [currentTheme]);
  useLayoutEffect(() => {
    resizeHandler();
    scrollHandler();
  }, [location]);

  useLayoutEffect(() => {
    render(canvasRef, currentTheme, true);
    if (prefersReducedMotion) return;
    const id = setInterval(() => {
      grid.update();
      render(canvasRef, currentTheme, false);
    }, 1000 / FPS);
    return () => {
      clearInterval(id);
    };
  }, [prefersReducedMotion, currentTheme]);

  return (
    <canvas
      ref={canvasRef}
      width={window.innerWidth}
      height={window.innerHeight}
      className="fixed -z-10"
    />
  );
};
