export class WireWorldGrid {
  hexRadius = 15;
  hexWidth = (this.hexRadius * 13) / 15;
  w = 48;
  h = 42;
  state: number[][] = [[]];
  prevState: number[][] = [[]];
  nonemptyCells: [number, number][] = [];

  getInitialState() {
    const g = `
ccc...c.c...ccc...c.c...ccc...ccc...c.c...c.c
c..c..c..c..c.....c..c..c........c..c..c..c..c
c...ccc...ccc...ccc...c.c.........c.c...c.c...cc
.c.....c..c.....c..c..c..c........c..c..c..c..c
..c.....HTc.....c...ccc...ccc...ccc...c.c...ccc
..c.....c..c.....c..c........c..c.....c..c..c
ccc.....c...ccc...c.c...ccc...ccc.....c...c.c
c..c.....c.....c..c..c..c..c..c..c.....c..c..c
c...ccc...ccc...ccc...ccc...ccc...ccc...ccc...c
.c..c..c..c..c..c.....c.....c.....c..c..c.....c
..c.c...ccc...c.c...ccc...HTc...ccc...ccc...ccc
..c..c........c..c..c.....c.....c........c..c
ccc...ccc...ccc...c.c...ccc...ccc...ccc...ccc
c..c..c..c..c.....c..c..c.....c..c..c..c..c..c
c...c.c...c.c...ccc...c.c...ccc...c.c...ccc...cc
.c..c..c..c..c..c..c..c..c..c.....c..c..c
..c.c...c.c...ccc...ccc...ccc...ccc...c.c
..c..c..c..c..c..c.....c..c.....c..c..c..c
ccc...c.c...ccc...c.....ccc.....c...ccc...ccc
c.....c..c..c.....c.....c..c.....c..c........c
c.....c...c.c...ccc.....c...ccc...c.c...ccc...cc
.c.....c..c..c..c..c.....c.....c..c..c..c..c..c
..ccc...ccc...c.c...ccc...ccc...ccc...ccc...HTc
..c..c..c.....c..c..c..c..c..c..c.....c.....c
ccc...ccc...ccc...c.c...ccc...c.c...ccc...ccc
c........c..c.....c..c........c..c..c.....c
c...ccc...ccc...ccc...ccc...ccc...c.c...ccc...cc
.c..c..c..c..c..c..c..c..c..c.....c..c..c.....c
..c.c...ccc...HTc...c.c...c.c...ccc...c.c...ccc
..c..c..c........c..c..c..c..c..c..c..c..c..c
ccc...c.c.........c.c...c.c...ccc...ccc...ccc
c..c..c..c........c..c..c..c..c..c.....c..c
c...ccc...ccc...ccc...c.c...ccc...c.....ccc
.c..c........c..c.....c..c..c.....c.....c..c
..c.c...ccc...ccc.....c...c.c...ccc.....c...ccc
..c..c..c..c..c..c.....c..c..c..c..c.....c.....c
ccc...ccc...ccc...ccc...ccc...c.c...HTc...ccc
c.....c.....c.....c..c..c.....c..c..c..c..c..c
c...ccc...ccc...ccc...ccc...ccc...c.c...ccc...c
.c..c.....c.....c........c..c.....c..c........c
..c.c...ccc...ccc...ccc...ccc...ccc...ccc...ccc
..c..c..c.....c..c..c..c..c..c..c..c..c..c..c`
      .trim()
      .split("\n");
    const STATES = ".HTc";
    const state = [];
    const prevState = [];
    const nonemptyCells: [number, number][] = [];
    for (let i = 0; i < g.length; i++) {
      const r = g[i];
      const row: number[] = [];
      for (let j = 0; j < r.length; j++) {
        const c = r[j];
        row.push(STATES.indexOf(c));
        if (c != " ") {
          nonemptyCells.push([i, j]);
        }
      }
      for (let j = r.length; j < this.w; j++) {
        row.push(0);
      }
      state.push(row);
      prevState.push([...row]);
    }
    this.state = state;
    this.prevState = prevState;
    this.nonemptyCells = nonemptyCells;
  }

  constructor() {
    this.getInitialState();
    this.h = this.state.length;
  }

  getState(i: number, j: number) {
    return this.state[i % this.h]?.[j % this.w];
  }
  getPrevState(i: number, j: number) {
    return this.prevState[i % this.h]?.[j % this.w];
  }
  setState(i: number, j: number, s: number) {
    this.state[i][j] = s;
  }

  indicesToScreen(i: number, j: number): [number, number] {
    const x = this.hexWidth * 2 * j - this.hexWidth * i;
    const y = this.hexRadius * 1.5 * i;
    return [x, y];
  }
  screenToIndices(x: number, y: number): [number, number] {
    let i = (y * 2) / (3 * this.hexRadius);
    let j = x / (2 * this.hexWidth) + y / (3 * this.hexRadius);
    i = ((i % this.h) + this.h) % this.h;
    j = ((j % this.w) + this.w) % this.w;
    [i, j] = this.roundIndices(i, j);
    return [i % this.h, j % this.w];
  }
  roundIndices(i: number, j: number): [number, number] {
    const r = j;
    const s = -i;
    const q = -r - s;
    let intR = Math.round(r);
    let intS = Math.round(s);
    let intQ = Math.round(q);
    const q_diff = Math.abs(intQ - q);
    const r_diff = Math.abs(intR - r);
    const s_diff = Math.abs(intS - s);

    if (q_diff > r_diff && q_diff > s_diff) intQ = -intR - intS;
    else if (r_diff > s_diff) intR = -intQ - intS;
    else intS = -intQ - intR;
    return [-intS, intR];
  }

  _getNeighbours(i: number, j: number): [number, number][] {
    const i1 = i == 0 ? this.h - 1 : i - 1;
    const i2 = i == this.h - 1 ? 0 : i + 1;
    const j1 = j == 0 ? this.w - 1 : j - 1;
    const j2 = j == this.w - 1 ? 0 : j + 1;
    return [
      [i2, j],
      [i2, j2],
      [i, j2],
      [i1, j],
      [i1, j1],
      [i, j1],
    ];
  }

  update() {
    [this.prevState, this.state] = [this.state, this.prevState];
    for (const [i, j] of this.nonemptyCells) {
      switch (this.prevState[i][j]) {
        case 1:
          this.state[i][j] = 2;
          break;
        case 2:
          this.state[i][j] = 3;
          break;
        case 3: {
          const litNeighbours = this._getNeighbours(i, j)
            .map(([x, y]) => +(this.prevState[x][y] === 1))
            .reduce((a, b) => a + b);
          if (litNeighbours == 1 || litNeighbours == 2) {
            this.state[i][j] = 1;
          } else {
            this.state[i][j] = 3;
          }
        }
      }
    }
  }
}
