import * as fs from "fs";

// Sample match text:
// export const metadata = {
//   title: "Note Title",
//   publishedOn: "2020-01-01T00:00:00Z",
// };
const metadataRegExp = new RegExp(
  "^export const metadata = ({" + // start delimiter
    "[\\s\\S]*?" + // metadata, match until end brace at start of line
    "^});$", // end delimiter
  "m" // flags
);

export function getNoteMetadata(): Record<string, NoteMetadata> {
  const noteDirPath = "./src/notes/";

  const notePathEntries = fs
    .readdirSync(noteDirPath)
    .filter((path) => path.endsWith(".mdx"));

  const metadata: Record<string, NoteMetadata> = {};
  for (const key of notePathEntries) {
    try {
      const filePath = noteDirPath + key;
      const slug = key.replace(/.mdx$/, "");
      const noteFile = fs.readFileSync(filePath, "utf8");

      const metadataMatch = noteFile.match(metadataRegExp);
      if (metadataMatch) {
        const data = new Function(`return (${metadataMatch[1]})`)();
        metadata[slug] = data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return metadata;
}

export const getGardenNotes = () => {
  const importedNotes = import.meta.glob<typeof import("*.mdx")>(
    "../notes/*.mdx"
  );
  return Object.keys(importedNotes).reduce(
    (notes, filepath) => {
      const slug = filepath.match("../notes/(.*).mdx")?.[1] ?? "";
      notes[slug] = importedNotes[filepath];
      return notes;
    },
    {} as typeof importedNotes
  );
};
