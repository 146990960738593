export function throttle<T extends (...args: never[]) => unknown>(
  fn: T,
  delayMs: number
) {
  let timeout = 0;

  return (...args: Parameters<T>) => {
    if (timeout) return;
    fn(...args);
    timeout = window.setTimeout(() => {
      timeout = 0;
    }, delayMs);
  };
}
